.money{
.black-text{
color: #000000ba;
}
.black-text2{
color: #000000ba;
font-size: 14px;
}
.contact{
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #000000ba;

    .link{
        color: rgb(51, 102, 255);
    }
}
.green-icon{
    color: #61b383;
}
.terms{
    margin-top: 10px;
    font-size: 14px;
    color: #000000ba;

    .link{
        color: rgb(51, 102, 255);
    }
}
.terms2{
    margin-top: 10px;
    font-size: 13px;
    color: #000000ba;

    .link{
        color: rgb(51, 102, 255);
    }
}

.currency{
    font-size: 16px;
    color: #a0a0a0d2;
    text-align: left;
    width: 100%;
    height: 100%;
    //center the text
    display: flex;
    align-items: center; 
}
.currency2{
    font-size: 14px;
    color: #a0a0a0d2;
    text-align: left;
    width: 100%;
    height: 100%;
    //center the text
    display: flex;
    align-items: center; 
}

.amount{
    font-size: 12px;
    font-weight: 500;
    color: rgb(133, 137, 150);
}


}

bb{
    color: rgba(0, 0, 0, 0.281);
}

.plan{
    
    .p-card{
        background-image: url("assets/images/upgrade.jpg");
        background-size: cover;
        background-repeat: no-repeat;

        .ptitle{
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
        }

        .p-price{
            font-size: 14px;
            color: #ffd69d;
        }

        .p-description{
            font-size: 14px;
            color: #ffe8c7;
        }

        .pdiv{
            background-color: #00000052;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            backdrop-filter: blur(2px);
        }

        .p-dailyProfits{
            font-size: 14px;
            font-weight: 500;
            color: #ffd69d;
            span{
                color: #f2e1c9f5;
                font-weight: 600;
            }
        }
        .pfooter{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 20px;
        }
        .pbutton{
            background-color: #d3891e;
            color: #ffffff;
            font-size: 14px;
            font-weight: 600;
            border-radius: 10px;
            width: 100%;
            height: 40px;
            border: none;
        }
        .pbutton:hover{
            background-color: #d17b04;
        }
    }
}
