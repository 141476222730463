.coin-card{
    margin-left: 12px;
    margin-right: 12px;
    background-color: $primary-color;
    min-width: 200px;
    min-height: 120px;
    box-shadow: 5px 5px 5px rgba(13, 4, 65, 0.24);
h6{
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 15px;
}

 .header-row{
padding-left: 15px;
 }
    .coin-title{
        color: rgb(255, 255, 255);
        font-size: 13px;
        font-weight: 600;
        margin: 0;
    }

    .coin-icon{
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    div{
       color: white;
       i{
        color: white;
        .fa{
           color: white;
        }
       }
    }
}

.coin-row{
    flex-wrap: nowrap;
    padding: 15px;
}

.coin-div{
    overflow: scroll;
}
