.container,
.container-sm,
.container-xs {
	width: 100%;
	margin: 0 auto;
	padding-left: $container--padding__mobile;
	padding-right: $container--padding__mobile;

	@include media( '>small' ) {
		padding-left: $container--padding__desktop;
		padding-right: $container--padding__desktop;
	}
}

.container {
	max-width: $container--width + ( $container--padding__desktop * 2 );
}

.container-sm {
	max-width: $container--width-sm + ( $container--padding__desktop * 2 );
}

.container-xs {
	max-width: $container--width-xs + ( $container--padding__desktop * 2 );
}

[class*=container] {

	[class*=container] {
		padding-left: 0;
		padding-right: 0;
	}

    .container-sm {
		max-width: $container--width-sm;
    }

	.container-xs {
		max-width: $container--width-xs;
	}
}

.background-radial-gradient {
	background-color: #252650;
	background-image: radial-gradient(650px circle at 0% 0%,
		#6164ff18 15%,
        #5658dd11 80%,
		transparent 100%),
	  radial-gradient(1250px circle at 100% 100%,
		hsl(218, 41%, 45%) 15%,
		hsl(218, 41%, 30%) 35%,
		hsl(218, 41%, 20%) 75%,
		hsl(218, 41%, 19%) 80%,
		transparent 100%);
  }
  
  #radius-shape-1 {
	height: 220px;
	width: 220px;
	top: -60px;
	left: -130px;
	background: radial-gradient(#5658DD, #6163FF );
	overflow: hidden;
  }
  
  #radius-shape-2 {
	border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
	bottom: -60px;
	right: -110px;
	width: 300px;
	height: 300px;
	background: radial-gradient(#44006b, #ad1fff);
	overflow: hidden;
  }
  
  .bg-glass {
	background-color: hsla(0, 0%, 100%, 0.8) !important;
	backdrop-filter: saturate(200%) blur(25px);
  }

  .translate{
	display: block;
    z-index: 2147483647;
    position: fixed;
    overflow: hidden;
    bottom: 10px;
    left: 24px;
	max-height: 20px;
    background: transparent;
}